import React from "react";

function Delete() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="19"
      fill="none"
      viewBox="0 0 14 19"
    >
      <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
        <path d="M14 5.175H0v13.5h14v-13.5zm-8.512 12.15h3.024v-10.8H5.487v10.8zm-1.35-10.8H1.35v10.8h2.788v-10.8zm8.512 10.8H9.863v-10.8h2.787v10.8z"></path>
        <path d="M5.05 2.25v-.9h3.9v.9h-3.9zm-1.35 0V0h6.6v2.25H14V3.6H0V2.25h3.7z"></path>
      </g>
    </svg>
  );
}

export default Delete;
