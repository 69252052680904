import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { Logo, User } from "../../svgIcons";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { DASHBOARD_SUPERADMIN_EMAILS } from '../../../utils/constant'

// import Styles from "./topNavigation.module.css"
export default function TopNavigationComponent(props) {
  const navigate = useNavigate();
  const { instance } = useMsal()
  const isAuthenticated  = useIsAuthenticated()
  const logout = () => {
    // console.log(isAuthenticated,'isAuthenticated')
    localStorage.removeItem("Authorization");
    if(isAuthenticated){
      instance.logoutRedirect()
    }
    navigate("/login");
  };
  return (
    <>
      <header className="top__navigation top__navigation--fixed">
        <div className="top__navigation--container">
          <div className="top__navigation--row">
            <div className="top__navigation--left">
              <div className="top__navigation--logo">
                <Link to="/" className="top__navigation--brand">
                  {/* <img src={Logo} alt="Gen-Z Media"/> */}
                  <Logo className="header-logo" />
                </Link>
              </div>
            </div>
            <div className="top__navigation--right">
              <div className="top__navigation--nav">
                <>
                  <Dropdown
                    trigger={["click"]}
                    placement="topRight"
                    overlayClassName="top__navigation--dropdown"
                    overlay={
                      <Menu>
                        <div className="profile__wrapper">
                          <div className="profile__name">
                            {props?.userData?.FirstName}{" "}
                            {props?.userData?.LastName}
                          </div>
                          <div className="profile__role">{DASHBOARD_SUPERADMIN_EMAILS.includes(props?.userData?.EmailAddress) ? "Super Admin" : "Admin"}</div>
                        </div>
                        {DASHBOARD_SUPERADMIN_EMAILS.includes(props?.userData?.EmailAddress) &&
                        <Menu.Item key="1">
                          <Link
                              to="/administration/users"
                              className="top__navigation--dropdown-link"
                            >
                              Users
                            </Link>
                          </Menu.Item>
                        }
                        <Menu.Item key="2">
                          <Link
                            to="/account/profile"
                            className="top__navigation--dropdown-link"
                          >
                            Profile
                          </Link>
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => logout()}
                          key="3"
                          className="top__navigation--dropdown-btn"
                        >
                          Sign out
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <a
                      className="ant-dropdown-link nav__link"
                      onClick={(e) => e.preventDefault()}
                    >
                      <User />
                    </a>
                  </Dropdown>
                </>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
